.App {
  text-align: center;
}

@font-face {
  font-family: 'SangSangShinb7';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_three@1.1/SangSangShinb7.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'UhBeeRami';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_five@.2.0/UhBeeRami.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  }

  @font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

  .pretendard-font {
    font-family: 'Pretendard-Regular', sans-serif;
  }
  
  .uhbee-rami-font {
    font-family: 'UhBeeRami', sans-serif;
  }

  .sangsang-shinb7-font {
    font-family: 'SangSangShinb7', sans-serif;
  }

